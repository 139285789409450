import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, Button, Popover } from '@mui/material';
import { setOpenFilterPopover } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DARKER_GREY, MAIN_BOX_SHADOW } from 'utils/theme';

const PopoverComponent = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openFilterPopover = useAppSelector(
    (state) => state.claimsV2.openFilterPopover
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setOpenFilterPopover(true));
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setOpenFilterPopover(false));
  };

  return (
    <Box>
      <Button
        aria-label="filter"
        onClick={handleClick}
        variant="contained"
        disableElevation
        endIcon={<FilterAltOutlinedIcon sx={{ color: '#8289A7' }} />}
        sx={{
          borderRadius: '20px',
          color: DARKER_GREY,
          background: 'rgba(0, 122, 255, 0.15)',
          textTransform: 'capitalize',
          '&:hover': {
            background: 'rgba(0, 122, 255, 0.15)',
          },
        }}
      >
        Filter
      </Button>
      <Popover
        id="simple-popover"
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
        PaperProps={{
          style: {
            boxShadow: MAIN_BOX_SHADOW,
            borderRadius: '12px',
          },
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

export default PopoverComponent;
