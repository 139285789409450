import { Box, Button, Stack, Typography } from '@mui/material';
import { setSelectedSourceDate } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { SourceDates } from 'pages/PlaygroundV2Page/helpers';
import { useDispatch } from 'react-redux';

const DateFilter = () => {
  const dispatch = useDispatch();
  const selectedSourceDate = useAppSelector(
    (state) => state.claimsV2.selectedSourceDate
  );

  const DateButton = ({
    value,
    label,
  }: {
    value: SourceDates;
    label: string;
  }) => (
    <Button
      variant="text"
      color="inherit"
      sx={{
        fontWeight: selectedSourceDate === value ? 700 : 300,
        color:
          selectedSourceDate === value ? 'rgba(0, 0, 0, 0.87)' : 'darkgrey',
        marginRight: '5px',
        textTransform: 'capitalize',
        fontSize: '0.875rem',
        p: 0,
        '&:hover': {
          background: 'none',
        },
      }}
      onClick={() => dispatch(setSelectedSourceDate(value))}
    >
      {label}
    </Button>
  );

  return (
    <Box>
      <Typography p={0} variant="subtitle1">
        Date published
      </Typography>
      <Stack flexDirection="row">
        <DateButton value={SourceDates.MOST_RECENT} label="Most recent" />
        <Typography pr={1} variant="subtitle1">
          /
        </Typography>
        <DateButton value={SourceDates.LEAST_RECENT} label="Least recent" />
      </Stack>
    </Box>
  );
};

export default DateFilter;
