import React from 'react';
import { Typography, Grid, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  BG_COLOR,
  DISPUTED,
  SUPPORTED,
  MIXED,
  DARKER_GREY,
  MEDIUM_FONT,
} from 'utils/theme';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';

interface SourceLabelProps {
  label: SourceTypes;
  iconSize?: 'small' | 'inherit' | 'medium' | 'large' | string;
}

const labelConfig = {
  [SourceTypes.SUPPORTING]: {
    color: SUPPORTED,
    icon: (size: string) => <CheckIcon sx={{ fontSize: size }} />,
  },
  [SourceTypes.DISPUTING]: {
    color: DISPUTED,
    icon: (size: string) => <CloseIcon sx={{ fontSize: size }} />,
  },
  [SourceTypes.MIXED]: {
    color: MIXED,
    icon: (size: string) => <RemoveIcon sx={{ fontSize: size }} />,
  },
};

const SourceLabel: React.FC<SourceLabelProps> = ({
  label,
  iconSize = 'small',
}) => {
  const { color, icon } = labelConfig[label] || {
    color: BG_COLOR,
    icon: () => null,
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: color,
        height: '28px',
        width: '120px',
        borderRadius: '4px',
        padding: '0 8px',
        marginRight: '8px',
      }}
    >
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon(iconSize)}
          <Typography
            color={DARKER_GREY}
            fontSize={MEDIUM_FONT}
            fontWeight="bold"
          >
            {label}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SourceLabel;
