import { Box } from '@mui/material';
import PopoverBody from './components/PopoverBody';
import PopoverComponent from './components/PopoverComponent';
import PopoverFooter from './components/PopoverFooter';
import PopoverHeader from './components/PopoverHeader';

const Filter = () => {
  return (
    <PopoverComponent>
      <Box py={3} px={4}>
        <PopoverHeader />
        <PopoverBody />
        <PopoverFooter />
      </Box>
    </PopoverComponent>
  );
};

export default Filter;
