import { IconButton, Tooltip } from '@mui/material';
import DiamondIcon from 'assets/img/DiamondIcon';
import { ReactElement } from 'react';
import { VANILLA_CREAM, YELLOW } from 'utils/theme';

interface DiamondButtonProps {
  onClick: () => void;
  tooltipTitle?: string;
}

interface TooltipIconProps {
  tooltipTitle: string;
  children: ReactElement;
}

const TooltipIcon = ({ tooltipTitle, children }: TooltipIconProps) => {
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>{children}</Tooltip>
  ) : (
    children
  );
};

const DiamondButton = ({ onClick, tooltipTitle }: DiamondButtonProps) => {
  return (
    <TooltipIcon tooltipTitle={tooltipTitle ?? ''}>
      <IconButton
        sx={{
          background: VANILLA_CREAM,
          border: `1.5px solid ${YELLOW}`,
          width: '61px',
          borderRadius: '32px',
        }}
        onClick={onClick}
      >
        <DiamondIcon sx={{ fontSize: '1.5rem' }} />
      </IconButton>
    </TooltipIcon>
  );
};

export default DiamondButton;
