import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { setSelectedSearchEngines } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { searchEngines } from 'pages/PlaygroundV2Page/helpers';
import { useDispatch } from 'react-redux';

const SearchEngineFilter = () => {
  const dispatch = useDispatch();
  const selectedSearchEngines = useAppSelector(
    (state) => state.claimsV2.selectedSearchEngines
  );

  const claim = useAppSelector((state) => state.claimsV2.claim);

  const claimSearchEngines = claim.evidence?.map((e) => e.searchEngine ?? '');

  return (
    <Stack direction="column">
      <Typography pb={1}>Select your preferred data</Typography>
      {searchEngines.map((searchEngine, i) => {
        const searchEnginesLength =
          claimSearchEngines?.filter((e) => e === searchEngine).length ?? 0;

        return (
          <FormControlLabel
            key={i}
            checked={selectedSearchEngines.includes(searchEngine)}
            onChange={() => {
              dispatch(
                setSelectedSearchEngines(
                  selectedSearchEngines.includes(searchEngine)
                    ? selectedSearchEngines.filter(
                        (value) => value !== searchEngine
                      )
                    : [...selectedSearchEngines, searchEngine]
                )
              );
            }}
            control={<Checkbox sx={{ py: '4px', px: 1 }} />}
            label={
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">{searchEngine}</Typography>
                {searchEnginesLength > 0 && (
                  <Typography variant="subtitle2">
                    ({searchEnginesLength})
                  </Typography>
                )}
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default SearchEngineFilter;
