import {
  filteredClaimResults,
  setSelectedSearchEngines,
  setSelectedSourceDate,
  setSelectedSourceTypes,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  areArraysEqual,
  searchEngines,
  sortSourcesByDate,
  SourceDates,
  SourceTypes,
  sourceTypeValues,
} from '../helpers';
import useScoreCount from '../hooks/useScoreCount';

export const useFilterActions = () => {
  const dispatch = useDispatch();
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const { supportingSources, mixedSources, disputingSources } = useScoreCount(
    claim.evidence
  );

  const selectedSearchEngines = useAppSelector(
    (state) => state.claimsV2.selectedSearchEngines
  );
  const selectedSourceTypes = useAppSelector(
    (state) => state.claimsV2.selectedSourceTypes
  );

  const selectedSourceDate = useAppSelector(
    (state) => state.claimsV2.selectedSourceDate
  );

  const resetFilters = useCallback(() => {
    dispatch(setSelectedSearchEngines(searchEngines));
    dispatch(setSelectedSourceTypes(sourceTypeValues));
    dispatch(setSelectedSourceDate(SourceDates.MOST_RECENT));

    dispatch(filteredClaimResults(claim));
  }, [claim, dispatch]);

  const applyFilters = useCallback(() => {
    const allSearchEnginesSelected =
      selectedSearchEngines.length === searchEngines.length;
    const allSourceTypesSelected =
      selectedSourceTypes.length === sourceTypeValues.length;
    const selectedSourceDateIsMostRecent =
      selectedSourceDate === SourceDates.MOST_RECENT;

    const sourcesCopy = [...(filteredClaim.evidence ?? [])];
    let newSources = sourcesCopy;

    const filterBySearchEngines = (sourceArray) => {
      if (!allSearchEnginesSelected) {
        return sourceArray.filter((source) =>
          selectedSearchEngines.includes(source.searchEngine ?? '')
        );
      }
      return sourceArray;
    };

    // check if filter has changed
    if (
      allSearchEnginesSelected &&
      allSourceTypesSelected &&
      selectedSourceDateIsMostRecent
    ) {
      newSources = claim.evidence ?? [];
    } else {
      const sourceTypesMap = [
        // no source is selected
        {
          condition: [],
          sources: [],
        },
        // all sources are selected
        {
          condition: [
            SourceTypes.SUPPORTING,
            SourceTypes.MIXED,
            SourceTypes.DISPUTING,
          ],
          sources: [supportingSources, mixedSources, disputingSources],
        },
        {
          condition: [SourceTypes.SUPPORTING, SourceTypes.MIXED],
          sources: [supportingSources, mixedSources],
        },
        // mixed and disputing are selected
        {
          condition: [SourceTypes.DISPUTING, SourceTypes.MIXED],
          sources: [mixedSources, disputingSources],
        },
        // supporting and disputing are selected
        {
          condition: [SourceTypes.DISPUTING, SourceTypes.SUPPORTING],
          sources: [supportingSources, disputingSources],
        },
        // supporting is selected
        {
          condition: [SourceTypes.SUPPORTING],
          sources: [supportingSources],
        },
        // disputing is selected
        {
          condition: [SourceTypes.DISPUTING],
          sources: [disputingSources],
        },
        // mixed is selected
        {
          condition: [SourceTypes.MIXED],
          sources: [mixedSources],
        },
      ];

      sourceTypesMap.some(({ condition, sources }) => {
        // if selectedSourceTypes are equal to sourceTypeMap sources
        // check if search engines has been changed and apply filters
        if (areArraysEqual(condition, selectedSourceTypes)) {
          newSources = filterBySearchEngines([].concat(...sources));
          return true;
        }
        return false;
      });
    }

    // last step sort by date
    const newSourcesSortedByDate = sortSourcesByDate(
      newSources,
      selectedSourceDate
    );

    dispatch(
      filteredClaimResults({
        ...filteredClaim,
        evidence: newSourcesSortedByDate,
      })
    );
  }, [
    claim.evidence,
    dispatch,
    disputingSources,
    filteredClaim,
    mixedSources,
    selectedSearchEngines,
    selectedSourceDate,
    selectedSourceTypes,
    supportingSources,
  ]);

  return {
    resetFilters,
    applyFilters,
  };
};

export default useFilterActions;
