import { Grid } from '@mui/material';
import DateFilter from './components/DateFilter';
import SearchEngineFilter from './components/SearchEngineFilter';
import SourceTypeFilter from './components/SourceTypeFilter';

const PopoverBody = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <SearchEngineFilter />
      </Grid>
      <Grid item xs={6} container justifyContent="flex-start">
        <DateFilter />
        <SourceTypeFilter />
      </Grid>
    </Grid>
  );
};

export default PopoverBody;
