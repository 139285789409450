import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import SourceLabel from './components/SourceLabel';
import { DARKER_GREY } from 'utils/theme';
import { SourceTypes } from '../helpers';
import SuggestedSearches from './components/SuggestedSearches';

interface NoSourcesFoundComponentProps {
  suggestedSearches?: string[];
}

const messages = {
  headerText: 'Not enough predictions',
  descriptionText:
    'Factiverse AI editor needs at least 5 supporting or disputing sources to predict and display results. Try rephrasing the claim.',
};

const NoSourcesFoundComponent: React.FC<NoSourcesFoundComponentProps> = ({
  suggestedSearches = [],
}) => {
  const isSuggestedSearchDataAvailable =
    suggestedSearches && suggestedSearches.length > 0;
  const gridColumnLeft = isSuggestedSearchDataAvailable ? 6 : 12;

  const Header: React.FC = () => (
    <Stack direction="row" alignItems="center" width="100%">
      <Typography variant="h6">{messages.headerText}</Typography>
    </Stack>
  );

  const LabelGroup: React.FC = () => (
    <Stack direction="row" alignItems="center" mt={3} spacing={1}>
      <SourceLabel label={SourceTypes.SUPPORTING} />
      <SourceLabel label={SourceTypes.DISPUTING} />
      <SourceLabel label={SourceTypes.MIXED} />
    </Stack>
  );

  const Description: React.FC = () => (
    <Stack direction="row" alignItems="center" mt={3} width="100%">
      <Typography color={DARKER_GREY}>{messages.descriptionText}</Typography>
    </Stack>
  );

  return (
    <Grid
      container
      sx={{
        bgcolor: 'white',
        borderRadius: '12px',
        mt: 2,
        p: 5,
        boxShadow: '4px 4px 18px rgba(128,155,181,0.1)',
      }}
    >
      <Grid
        item
        xs={gridColumnLeft}
        sx={{
          borderRight: isSuggestedSearchDataAvailable
            ? '1px solid lightgrey'
            : 0,
        }}
      >
        <Header />
        <LabelGroup />
        <Description />
      </Grid>
      {isSuggestedSearchDataAvailable && (
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <SuggestedSearches suggestedSearchesData={suggestedSearches} />
        </Grid>
      )}
    </Grid>
  );
};

export default NoSourcesFoundComponent;
