import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from 'assets/img/SearchIcon';
import {
  filteredClaimResults,
  setClaimResults,
  setIsNoDataFound,
  setLinearProgressValue,
  setSearchValue,
  setSelectedSearchEngines,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import useUserMetaData from 'hooks/useUserMetaData';
import {
  getErrorText,
  isTooLongText,
  searchEngines,
} from 'pages/PlaygroundV2Page/helpers';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import { useDispatch } from 'react-redux';
import { BORDER_COLOR, GREY, RED } from 'utils/theme';
import GetProButton from './GetProButton';

export const SearchFieldBody = () => {
  const dispatch = useDispatch();
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const checkClaim = useCheckClaim();
  const { noOrTrialSubscription } = useUserMetaData();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    dispatch(setSearchValue(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!isTooLongText(searchValue) && searchValue.length > 0) {
        checkClaim(searchValue);
      }
    }
  };

  return (
    <TextField
      id="outlined-basic"
      placeholder="Type or paste your text here"
      variant="outlined"
      sx={{
        background: 'white',
        '& fieldset': { border: 'none' },
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
        borderBottomRightRadius:
          claimLoading || noOrTrialSubscription ? '0px' : '12px',
        borderBottomLeftRadius:
          claimLoading || noOrTrialSubscription ? '0px' : '12px',
      }}
      value={searchValue}
      multiline
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchValue.length > 0 && (
              <IconButton
                onClick={() => {
                  dispatch(setSearchValue(''));
                  dispatch(setClaimResults({} as Claim));
                  dispatch(filteredClaimResults({} as Claim));
                  dispatch(setLinearProgressValue(0));
                  dispatch(setIsNoDataFound(false));
                  dispatch(setSelectedSearchEngines(searchEngines));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
            {searchValue.length > 0 && (
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                disabled={isTooLongText(searchValue)}
                onClick={() => checkClaim(searchValue)}
              >
                <SearchIcon fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SearchFieldFooter = () => {
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        background: 'white',
        borderBottomRightRadius: claimLoading ? '0px' : '12px',
        borderBottomLeftRadius: claimLoading ? '0px' : '12px',
        borderTop: `1px solid ${BORDER_COLOR}`,
        paddingY: '12px',
        paddingX: '30px',
      }}
      alignItems="center"
    >
      <Typography
        variant="subtitle2"
        sx={{ color: isTooLongText(searchValue) ? RED : GREY, pr: 1 }}
      >
        {getErrorText(searchValue) ?? 'Free version 30 words'}
      </Typography>
      <Stack>
        <GetProButton />
      </Stack>
    </Stack>
  );
};
