import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { setSelectedSourceTypes } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes, sourceTypeValues } from 'pages/PlaygroundV2Page/helpers';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ISMOBILE_BREAKPOINT } from 'utils/theme';

const SourceTypeFilter = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const selectedSourceTypes = useAppSelector(
    (state) => state.claimsV2.selectedSourceTypes
  );

  const handleChangeTypeValues = (
    event: ChangeEvent<HTMLInputElement>,
    item: SourceTypes
  ) => {
    dispatch(
      setSelectedSourceTypes(
        event.target.checked
          ? [...selectedSourceTypes, item]
          : selectedSourceTypes.filter((value) => item !== value)
      )
    );
  };

  const handleChangeTypeValuesAll = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setSelectedSourceTypes(event.target.checked ? sourceTypeValues : [])
    );
  };

  return (
    <Stack>
      <Typography p={0} variant="subtitle1" pl={isMobile ? 0 : 0.5}>
        Type of source
      </Typography>
      <Box pl="0.5em">
        <FormControl component="fieldset" variant="standard" margin="none">
          <FormGroup>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sourceTypeValues
                        .map((c) => selectedSourceTypes.includes(c))
                        .every((v) => v === true)}
                      onChange={handleChangeTypeValuesAll}
                      name="All"
                      sx={{ py: '4px', px: 1 }}
                    />
                  }
                  label={<Typography variant="subtitle2">All</Typography>}
                />
              </Grid>
              {sourceTypeValues.map((checkbox, key) => (
                <Grid item xs={6} key={key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSourceTypes?.includes(checkbox)}
                        onChange={(e) => handleChangeTypeValues(e, checkbox)}
                        name={checkbox}
                        value={checkbox}
                        sx={{ py: '4px', px: 1 }}
                      />
                    }
                    label={
                      <Typography variant="subtitle2">{checkbox}</Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default SourceTypeFilter;
