import { Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import Sum from 'components/Sum';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useScoreCount from 'pages/PlaygroundV2Page/hooks/useScoreCount';
import useScrollToClaims from 'pages/PlaygroundV2Page/hooks/useScrollToClaims';
import { LIGHT_GREEN, LIGHT_PINK, LIGHT_YELLOW } from 'utils/theme';

const palette = [LIGHT_GREEN, LIGHT_YELLOW, LIGHT_PINK];

const pieParams = { height: 200, margin: { right: 5 } };

const Donut = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const evidence = useAppSelector((state) => state.claimsV2.claim.evidence);

  const { supportingSources, mixedSources, disputingSources } =
    useScoreCount(evidence);

  const donutData = [
    {
      id: 1,
      value: supportingSources?.length ?? 0,
      title: SourceTypes.SUPPORTING,
    },
    { id: 2, value: mixedSources?.length ?? 0, title: SourceTypes.MIXED },
    {
      id: 3,
      value: disputingSources?.length ?? 0,
      title: SourceTypes.DISPUTING,
    },
  ];

  const series = [{ data: donutData, innerRadius: 50, outerRadius: 70 }];

  const CustomLegend = ({ data, palette }) => {
    const { handleScrollToClaim } = useScrollToClaims();

    return (
      <Stack gap={1}>
        {data.map((entry, index) => (
          <Stack direction="row" gap={1} alignItems="center" key={entry.id}>
            {claimLoading ? (
              <Skeleton width={200} height={30} />
            ) : (
              <Stack direction="row" gap={2}>
                <Sum
                  color={palette[index % palette.length]}
                  count={entry.value}
                  isSum={true}
                />
                <Button
                  variant="text"
                  onClick={handleScrollToClaim}
                  sx={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                    color: 'black',
                    p: 0,
                    justifyContent: 'flex-start',
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  <Typography variant="subtitle1">{entry.title}</Typography>
                </Button>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <Grid container>
      <Grid container item xs={6} justifyContent="center" width="100%">
        {claimLoading ? (
          <Skeleton variant="circular" width={150} height={150} />
        ) : (
          <PieChart colors={palette} series={series} {...pieParams} />
        )}
      </Grid>
      <Grid container item xs={6} justifyContent="center" alignItems="center">
        <CustomLegend data={donutData} palette={palette} />
      </Grid>
    </Grid>
  );
};

export default Donut;
