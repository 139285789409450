import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { setOpenFilterPopover } from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';

const PopoverHeader = () => {
  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography fontSize="1.15rem">Customise your results</Typography>
      <IconButton
        aria-label="closeIcon"
        onClick={() => dispatch(setOpenFilterPopover(false))}
      >
        <CloseIcon
          sx={{ color: 'rgba(111, 111, 111, 0.6)' }}
          fontSize="small"
        />
      </IconButton>
    </Stack>
  );
};

export default PopoverHeader;
