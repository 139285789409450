import { IconButton, Typography } from '@mui/material';
import DiamondIcon from 'assets/img/DiamondIcon';
import { useNavigate } from 'react-router-dom';
import { DARKER_GREY, VANILLA_CREAM, YELLOW } from 'utils/theme';

const GetProButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        navigate('/pricing');
      }}
      sx={{
        background: VANILLA_CREAM,
        border: `1px solid ${YELLOW}`,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '0px',
        px: 1,
        py: 0,
      }}
    >
      <DiamondIcon
        sx={{ fontSize: '1rem', right: '2px', position: 'relative' }}
      />
      <Typography variant="body2" color={DARKER_GREY}>
        Get Pro
      </Typography>
    </IconButton>
  );
};

export default GetProButton;
