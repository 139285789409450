import { Button } from '@mui/material';
import { YELLOW } from 'utils/theme';

interface YellowButtonProps {
  children: string;
  onClick: () => void;
  disabled?: boolean;
}

const YellowButton = (props: YellowButtonProps) => {
  const { children, onClick, disabled } = props;
  return (
    <Button
      sx={{
        border: disabled ? 'lightgrey' : `1px solid ${YELLOW}`,
        borderRadius: '20px',
        color: 'black',
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        background: disabled ? 'lightgrey' : YELLOW,
        '&:hover': {
          backgroundColor: 'white',
        },
        paddingX: '1em',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default YellowButton;
