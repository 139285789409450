import { Skeleton, Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { extractSummaryTextFromClaim } from 'pages/PlaygroundV2Page/helpers';
import CopyToClipboardButton from './CopyToClipboardButton';

const SummaryFooter = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const claim = useAppSelector((state) => state.claimsV2.claim);

  const textToCopy = extractSummaryTextFromClaim(claim);

  return (
    <Stack direction="row" alignItems="center" width="100%">
      {claimLoading ? (
        <Skeleton width="inherit" height={50} sx={{ mx: 2 }} />
      ) : (
        <CopyToClipboardButton textToCopy={textToCopy} />
      )}
    </Stack>
  );
};

export default SummaryFooter;
