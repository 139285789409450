import { Stack } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import { setOpenFilterPopover } from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';
import useFilterActions from '../useFilterActions';

const PopoverFooter = () => {
  const dispatch = useDispatch();

  const { applyFilters, resetFilters } = useFilterActions();

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={1}>
      <YellowButton
        onClick={() => {
          applyFilters();
          dispatch(setOpenFilterPopover(false));
        }}
      >
        Apply
      </YellowButton>
      <YellowBorderButton
        onClick={() => {
          resetFilters();
          dispatch(setOpenFilterPopover(false));
        }}
      >
        Reset Filters
      </YellowBorderButton>
    </Stack>
  );
};

export default PopoverFooter;
