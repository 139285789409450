import { Box, Stack } from '@mui/material';
import SortingAccordion from './SortingAccordion';
import Filter from 'pages/PlaygroundV2Page/Filter';

const ClaimSummary = ({ claim }: { claim: Claim }) => (
  <Box
    sx={{
      background: 'white',
      px: 4,
      py: 3,
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      mb: 2,
      mt: 3,
    }}
  >
    <Stack direction="row" justifyContent="space-between" spacing={4}>
      {claim.evidence && <SortingAccordion evidence={claim.evidence} />}
      <Filter />
    </Stack>
  </Box>
);

export default ClaimSummary;
