import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Deployment, useDeployment } from './useDeployment';

export enum SubscriptionPlan {
  Lite = 'lite',
  Pro = 'pro',
  Trial = 'trial',
  None = 'none',
}

export const useUserMetaData = () => {
  // We store some meta data about users in their authentication tokens
  const { user } = useAuth0();
  const { deployment } = useDeployment();

  // specifies the subscription plan the user is on
  const [noSubscription, setNoSubscription] = useState(false);
  const [trialSubscription, setTrialSubscription] = useState(true);
  const [liteSubscription, setLiteSubscription] = useState(false);
  const [proSubscription, setProSubscription] = useState(false);

  const resetSubscriptions = () => {
    setNoSubscription(false);
    setTrialSubscription(true);
    setLiteSubscription(false);
    setProSubscription(false);
  };

  // timestamp of the first login to Playground
  const userRegisterDate = useMemo(() => {
    return moment(user?.editor_created_at).format('YYYY-MM-DD');
  }, [user]);

  // the free trial should run out after 14 days
  const userTrialVersionEnd = useMemo(() => {
    return moment(user?.editor_created_at).add(14, 'day').format('YYYY-MM-DD');
  }, [user]);

  // set the subscription plan depending on the Stripe product id
  useEffect(() => {
    const product = user?.stripe_product;
    resetSubscriptions();
    switch (product) {
      case 'prod_OnFW64hYG1GXAW':
        setLiteSubscription(true);
        break;
      case 'prod_OndvvGGHoreI3m':
        setProSubscription(true);
        break;
      case 'prod_NvHqhosSJnB22R': // old "unlimited Factiverse" subscription plan
        setProSubscription(true);
        break;
      default:
        setNoSubscription(true);
        break;
    }
    if (
      product == undefined &&
      moment(moment()).isBefore(userTrialVersionEnd)
    ) {
      setTrialSubscription(true);
      setProSubscription(true);
      setNoSubscription(false);
    } else {
      setTrialSubscription(false);
    }

    // Set users on DNB pilot deployment to Pro users
    if (deployment === Deployment.pilot) {
      setNoSubscription(false);
      setTrialSubscription(true);
      setLiteSubscription(false);
      setProSubscription(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const trialDates = useMemo(() => {
    const dateArray: string[] = [];
    let currentDate = moment(userRegisterDate);
    const endDate = moment(userTrialVersionEnd);
    while (currentDate <= endDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }, [userRegisterDate, userTrialVersionEnd]);

  const valueByDay = useMemo(
    () =>
      trialDates
        .map((date, index) => ({
          date: date,
          value: ((index + 1) * 100) / trialDates.length,
        }))
        .find((date) => moment(date.date).isSame(moment(), 'day')),
    [trialDates]
  );

  const noOrTrialSubscription = noSubscription || trialSubscription;

  return {
    noSubscription,
    trialSubscription,
    liteSubscription,
    proSubscription,
    valueByDay,
    noOrTrialSubscription,
  };
};

export default useUserMetaData;
