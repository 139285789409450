import React, { useState } from 'react';
import { Button } from '@mui/material';
import SearchIcon from 'assets/img/SearchIcon';

interface SuggestedButtonProps {
  claim: string;
  onClick: () => void;
  backgroundColor?: string;
  color?: string;
  border?: string;
  hoverBackgroundColor?: string;
  hoverBorderColor?: string;
  hoverStrokeColor?: string;
}

const SuggestedButton: React.FC<SuggestedButtonProps> = ({
  claim,
  onClick,
  backgroundColor = 'white',
  color = '#212427',
  border,
  hoverBackgroundColor,
  hoverBorderColor,
  hoverStrokeColor,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Button
      sx={{
        background: backgroundColor,
        textTransform: 'none',
        borderRadius: '30px',
        paddingY: '10px',
        paddingX: '16px',
        color: color,
        border: border,
        textAlign: 'left',
        justifyContent: 'flex-start',
        transition: 'background-color 0.3s, border-color 0.3s',
        '&:hover': {
          backgroundColor: hoverBackgroundColor,
          borderColor: hoverBorderColor,
        },
      }}
      onClick={onClick}
      endIcon={
        <SearchIcon
          sx={{ pr: 1, pl: 2 }}
          stroke={isHovered ? hoverStrokeColor : '#6F6F6F'}
        />
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {claim}
    </Button>
  );
};

export default SuggestedButton;
