import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { useMemo } from 'react';
import { SourceTypes } from '../helpers';
import ClaimEvidence from './components/ClaimEvidence';
import ClaimSummary from './components/ClaimSummary';

const Claim = () => {
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const sortedSources = useAppSelector((state) => state.claimsV2.sources);
  const selectedSortingAccordionName = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordionName
  );

  const sortedOrFilteredSources = useMemo(() => {
    if (selectedSortingAccordionName === SourceTypes.ALL) {
      return filteredClaim.evidence ?? [];
    } else {
      return sortedSources;
    }
  }, [filteredClaim.evidence, selectedSortingAccordionName, sortedSources]);

  return (
    <Box>
      {filteredClaim && <ClaimSummary claim={filteredClaim} />}
      <Grid container rowSpacing={2} columnSpacing={2}>
        {sortedOrFilteredSources?.map((source, index) => (
          <Grid item key={index} xs={12} xl={6} pb={2}>
            <ClaimEvidence index={index} evidence={source} key={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Claim;
