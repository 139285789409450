import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import Sum from 'components/Sum';
import {
  setSelectedSortingAccordionName,
  setSources,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useScoreCount from 'pages/PlaygroundV2Page/hooks/useScoreCount';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import {
  BRIGHT_BLUE,
  DARK_GREY,
  ISMOBILE_BREAKPOINT,
  LIGHT_GREEN,
  LIGHT_GREY,
  LIGHT_PINK,
  LIGHT_YELLOW,
  SMALL_FONT,
} from 'utils/theme';

const AccordionItem = ({
  title,
  itemColor,
  count,
  onClick,
  endIcon,
  titleColor,
}: {
  title: string;
  itemColor: string;
  count: number;
  endIcon: ReactNode;
  onClick: () => void;
  titleColor: string;
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  return (
    <Button onClick={onClick} endIcon={endIcon}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={0.5}
        alignItems="center"
      >
        <Sum color={itemColor} count={count} isSum={true} />
        <Typography fontSize={SMALL_FONT} color={titleColor}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );
};

const SortingAccordion = ({ evidence }: { evidence: Source[] }) => {
  const dispatch = useDispatch();
  const { supportingSources, mixedSources, disputingSources } =
    useScoreCount(evidence);
  const selectedSortingAccordionName = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordionName
  );

  const allSources = [supportingSources, mixedSources, disputingSources].flat();

  const accordionItems = [
    {
      name: SourceTypes.ALL,
      itemColor: LIGHT_GREY,
      items: allSources as Source[],
    },
    {
      name: SourceTypes.SUPPORTING,
      itemColor: LIGHT_GREEN,
      items: supportingSources,
    },
    {
      name: SourceTypes.MIXED,
      itemColor: LIGHT_YELLOW,
      items: mixedSources,
    },
    {
      name: SourceTypes.DISPUTING,
      itemColor: LIGHT_PINK,
      items: disputingSources,
    },
  ];

  return (
    <Stack direction="row">
      {accordionItems.map(({ name, itemColor, items }, key) => {
        return (
          <AccordionItem
            key={key}
            title={name}
            count={items?.length ?? 0}
            itemColor={itemColor}
            onClick={() => {
              dispatch(setSources(items ?? []));
              dispatch(setSelectedSortingAccordionName(name));
            }}
            titleColor={
              selectedSortingAccordionName === name ? BRIGHT_BLUE : DARK_GREY
            }
            endIcon={
              selectedSortingAccordionName === name ? (
                <ExpandLess sx={{ color: BRIGHT_BLUE }} />
              ) : (
                <ExpandMore sx={{ color: DARK_GREY }} />
              )
            }
          />
        );
      })}
    </Stack>
  );
};

export default SortingAccordion;
