import { Box } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import Claim from './Claim';
import Header from './Header';
import NavBar from './NavBar';
import NoSourcesFoundComponent from './NoSourcesFoundComponent';
import SearchField from './SearchField';
import SuggestedClaimButtons from './SuggestedClaimButtons';
import Summary from './Summary';
import useScrollToClaims from './hooks/useScrollToClaims';

const navbarWidth = 145;
const bodyWidth = 1059;

const PlaygroundV2Page = () => {
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const claimHasEvidence = claim.evidence && claim.evidence?.length > 0;
  const isNoDataFound = useAppSelector((state) => state.claimsV2.isNoDataFound);
  const { scrollTargetRef } = useScrollToClaims();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: !claimLoading && claimHasEvidence ? 'auto' : '100vh',
      }}
    >
      <Box sx={{ width: `${navbarWidth}px` }}>
        <NavBar />
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
      >
        <Box sx={{ width: `${bodyWidth}px` }}>
          <Header />
          <SearchField />
          {/* show when there is no evidence was found */}
          {!claimLoading && isNoDataFound && <NoSourcesFoundComponent />}
          {/* show when there is no data and not loading */}
          {!claimLoading && !claimHasEvidence && <SuggestedClaimButtons />}
          {/* show when there is data and loading */}
          {(claimLoading || claimHasEvidence) && <Summary />}
          {/* show when there is data and not loading */}
          {!claimLoading && claimHasEvidence && (
            <Box ref={scrollTargetRef}>
              <Claim />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PlaygroundV2Page;
